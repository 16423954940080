<template>
  <div class="page">
    <div class="page-header">
      <h1 class="page-title">Expiration Campaign</h1>
      <breadcrumb name="ILLICIT_BARCODE_EXPIRATION_CAMPAIGN"></breadcrumb>
      <div class="page-header-actions">
        <button
          type="button"
          class="btn btn-icon btn-dark btn-outline btn-round mx-10"
          @click="reloadTable"
        >
          <i class="icon md-refresh-alt" aria-hidden="true"></i> Reload table
        </button>

        <button
          type="button"
          class="btn btn-round btn-dark btn-outline mx-10"
          @click="hideArchive = !hideArchive"
        >
          {{ hideArchive ? "Show all" : "Hide Archive" }}
        </button>

        <button
          type="button"
          class="btn btn-round btn-primary"
          @click="openCreateModal"
        >
          <i class="icon md-plus" aria-hidden="true"></i> Create
        </button>
      </div>
    </div>
    <div class="page-content page-content-table">
      <div class="container-fluid">
        <div class="panel">
          <div class="panel-body p-0">
            <vue-good-table
              ref="table"
              :rows="list"
              :columns="columns"
              @on-row-dblclick="onRowDoubleClick"
              :sort-options="sortOption"
              :search-options="searchOption"
              :pagination-options="pagination"
              @on-search="onSearch"
              @on-sort-change="onSortChange"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'end_date'">
                  {{ props.row.end_date == null ? "n/a" : props.row.end_date }}
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" :width="500" height="auto">
      <CreateModal @submit="fetchcampaign" />
    </modal>
  </div>
</template>

<script>
import { expirationService } from "@/api/client";
import vueGoodTableMixins from "@/mixins/vue-good-table";
import { columns } from "@/views/illicit-barcode/expiration-campaign/model";
import CreateModal from "@/views/illicit-barcode/expiration-campaign/CreateModal.vue";

export default {
  name: "ExpirationCampaignList",

  mixins: [vueGoodTableMixins],

  data() {
    return {
      list: [],
      campaigns: [],
      hideArchive: true,
      columns: columns,
    };
  },

  components: { CreateModal },

  watch: {
    hideArchive() {
      this.filter();
    },

    campaigns(v) {
      this.list = v;
    },
  },

  methods: {
    async onRowDoubleClick(event) {
      this.$router
        .push(`/illicit-barcode/expiration-campaign/edit/${event.row.id}`)
        .catch((err) => {});
    },

    async fetchcampaign(show_all) {
      this.campaigns = await expirationService.getCampaignList({
        show_all: show_all,
      });
    },

    filter() {
      this.hideArchive ? this.fetchcampaign(0) : this.fetchcampaign(1);
    },

    async reloadTable() {
      await this.fetchcampaign();
      this.$notify("Updated");
    },

    async openCreateModal() {
      this.$modal.show(`create-modal`);
    },
  },

  beforeMount() {
    this.initialTableState();
  },

  mounted() {
    this.initialTableSearchState();
  },

  created() {
    this.fetchcampaign(0);
  },
};
</script>

<style lang="scss">
table.vgt-table tr {
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
}
</style>
