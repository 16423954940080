<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="panel panel-bordered mb-0">
        <div class="panel-heading">
          <h3 class="panel-title">Create Expiration Barcode Campaign</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal">
            <div
              class="form-group row"
              :class="{ '.is-invalid': $v.campaign.name.$error }"
            >
              <label class="col-md-0 form-control-label form__label"
                ><h5>Campaign Name</h5>
              </label>
              <div class="col-md-12">
                <input
                  type="text"
                  ref="name"
                  class="form-control form__input"
                  v-model="campaign.name"
                  autocomplete="off"
                  @input="showValid('name')"
                />
                <nameValidation :$v="$v" v-if="showErrorNameValidation" />
              </div>
            </div>
            <div class="form-group row">
              <h5 class="col-md-12">Copied From</h5>
              <div class="col-md-12">
                <select class="form-control col-md-12" v-model="copyFromId">
                  <option value="" disabled>Select Copy From</option>
                  <option
                    v-for="campaign in campaigns"
                    :key="campaign.id"
                    :value="campaign.id"
                  >
                    {{ ` ${campaign.id} - ${campaign.name}` }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="panel-footer">
          <div class="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-round btn-default mx-10"
              @click="close"
            >
              <i class="icon md-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-round btn-primary"
              @click="addCampaign"
              :disabled="$v.campaign.name.$invalid"
            >
              <i class="icon md-check" aria-hidden="true"></i> Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { expirationService } from "@/api/client";
import { responseErrorMapping } from "./model/error";
import nameValidation from "./validation/name";
import { FormCreateValidateRule as validateRules } from "./validation";

export default {
  name: "CreateModal",

  data() {
    return {
      campaign: {
        name: "",
      },
      campaigns: [],
      copyFromId: null,
      showErrorNameValidation: false,
    };
  },

  components: {
    nameValidation,
  },

  validations: { campaign: validateRules },

  methods: {
    async getFormData() {
      // เลือก copy from
      if (this.copyFromId) {
        return { copy_from_id: this.copyFromId, name: this.campaign.name };
      }

      // ไม่ได้เลือก copy from
      else {
        return {
          name: this.campaign.name,
        };
      }
    },

    validate() {
      if (this.$v.campaign.name.$invalid) {
        this.$refs.name.focus();
        throw new Error("กรุณากรอก Campaign Name ให้ถูกต้อง");
      }
    },

    async addCampaign() {
      try {
        this.validate();

        const body = await this.getFormData();

        await expirationService.createCampaign(body);
        this.$notify("Create Illicit expiration campaign Success.");
        this.$emit("submit");
        this.close();
      } catch (error) {
        error.response
          ? this.$dialogs.alert(responseErrorMapping(error.response.data))
          : this.$dialogs.alert(error);
      }
    },

    showValid(name) {
      if (name == "name") {
        this.showErrorNameValidation = true;
      }
    },

    close() {
      this.$modal.hide("create-modal");
    },
  },

  async mounted() {
    this.campaigns = await expirationService.getCampaignList();
  },
};
</script>
