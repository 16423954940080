export const columns = [
  {
    label: "ID",
    field: "id",
    type: "number",
  },
  {
    label: "Campaign Name ",
    field: "name",
  },
  {
    label: "Start Date",
    field: "start_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "End Date",
    field: "end_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Status",
    field: "status",
    formatFn: function (value) {
      return value.toUpperCase();
    },
  },
  {
    label: "Last Modified",
    field: "modified_time",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd",
  },
  {
    label: "Modified By",
    field: "modified_by",
  },
];
